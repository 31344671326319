import React from "react";
import Slider from "react-slick";
import styles from "./carousel.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Carousel({ content, slides }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    arrows: false,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headlineContainer}>
        <h1>{content.heading}</h1>
        {content.slogan && (
          <h2 style={{ letterSpacing: "2px" }}>{content.slogan}</h2>
        )}
      </div>
      <Slider {...settings} className={styles.slider}>
        {slides.map((slide, i) => (
          <img src={slide} alt="" key={i} className={styles.slide} />
        ))}
      </Slider>
    </div>
  );
}
